import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Overline from "components/overline"
import FadeIn from "transitions/fade-in"

const ServicesHero = () => {
  const { wpPage } = useStaticQuery(graphql`
    query ServicesHero {
      wpPage(databaseId: { eq: 3851 }) {
        services {
          hero: servicesHero {
            title
            heading
          }
        }
      }
    }
  `)
  const { heading, title } = wpPage.services.hero

  return (
    <section className="c-hero">
      <div className="container relative justify-center text-center">
        <FadeIn delay="0">
          <Overline>{title}</Overline>
        </FadeIn>
        <FadeIn delay="300">
          <div
            className="c-hero__heading style-links"
            dangerouslySetInnerHTML={{ __html: heading }}
          ></div>
        </FadeIn>
      </div>
    </section>
  )
}

export default ServicesHero
