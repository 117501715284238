import React from "react"
import { graphql } from "gatsby"
import SEO from "components/seo"
import Transition from "components/transition"
import ServicesHero from "components/services/hero"
import Products from "components/services/products"
import Websites from "components/services/websites"
import Branding from "components/services/branding"
import Other from "components/services/other"
import ExploreOurWork from "components/about/explore-our-work"
import Divider from "components/divider"
import ArticlesMarquee from "components/flexible/articles-marquee"

const Services = React.memo(({ data: { wpPage } }) => {
  if (!wpPage) return null
  const { seo, services } = wpPage

  return (
    <Transition>
      <SEO
        seo={seo}
        bodyAttributes={{
          class: "page--services",
        }}
      />
      <ServicesHero />
      <Products />
      <Branding />
      <Websites />
      <ArticlesMarquee {...wpPage.services.articlesMarqueeServices} />
      <ExploreOurWork data={services.exploreOurWork} />
    </Transition>
  )
})

export const query = graphql`
  query Services {
    wpPage(databaseId: { eq: 3851 }) {
      ...SEO
      services {
        exploreOurWork {
          heading
          content
          caseStudies {
            ...CaseStudyCard
          }
        }
        articlesMarqueeServices {
          heading
          content
          items {
            ... on WpExternalArticle {
              ...ExternalArticle
            }
          }
        }
      }
    }
  }
`

export default Services
