import React, { useState } from "react"
import { renderImage } from "utils/imageRender"
import Link from "components/transition-link"
import { useAutoProgressTimeout } from "utils/useAutoProgressTimeout"
import { useInViewStart } from "utils/useInViewStart"
import Star from "assets/star.svg"

const Gallery = ({ items }) => {
	const [ref, started] = useInViewStart()
	const frameLength = 5
	const [active, setActive] = useAutoProgressTimeout(
		started,
		frameLength,
		items.length - 1
	)
	return (
		<div ref={ref} className="">
			<div className="relative" style={{ paddingBottom: "113%" }}>
				{items.map(({ image }, i) => {
					let className =
						"absolute top-0 left-0 right-0 bottom-0 opacity-0 duration-500 transition-opacity "
					if (i == 0) className += "bg-black"
					if (i == 1) className += "bg-turquoise"
					if (i == 2) className += "bg-raven"
					if (active == i) {
						className += " opacity-100 delay-0"
					} else {
						className += " delay-500"
					}
					return (
						<div key={`gallery-image-${i}`} className={className}>
							{renderImage(image, "absolute inset-0 w-full h-full")}
						</div>
					)
				})}
			</div>
			<div className="flex justify-between items-center mt-4 mx-4 md:mx-0">
				{items.map(({ link, projectType }, i) => {
					if (active != i) return null
					return (
						<div
							className="md:ml-2 c-tile__caption style-links mt-0"
							key={`gallery-text-${i}`}
						>
							{link && <Link to={link.url}>{link.title}</Link>}
							<div className="mx-3 w-6px h-6px text-raven">
								<Star />
							</div>
							{projectType}
						</div>
					)
				})}
				{items.length > 1 && (
					<div
						className="hidden md:flex items-center space-x-1"
						style={{ width: "50px" }}
					>
						{items.map(({ projectType }, i) => {
							const isActive = i == active
							return (
								<div
									key={`star-${i}`}
									onClick={() => {
										setActive(i)
									}}
									className={`bg-iron px-1 py-1 relative cursor-pointer`}
								>
									{isActive && (
										<div className="gallery-timer-animation absolute top-0 left-0 bottom-0 bg-black"></div>
									)}
									<span
										className={`block relative text-white transition-opacity duration-500 ${
											isActive ? "" : "opacity-0"
										}`}
									>
										<Star colour="white" width="6px" />
									</span>
								</div>
							)
						})}
					</div>
				)}
			</div>
		</div>
	)
}

export default Gallery
