import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import TwoColumns from "layouts/two-columns"
import { renderImage } from "utils/imageRender"
import Star from "assets/star.svg"
import Link from "components/transition-link"
import TwoColumnGallery from "components/services/two-column-gallery"
import FadeIn from "transitions/fade-in"

const Products = () => {
  const { wpPage } = useStaticQuery(graphql`
    query ServicesProduct {
      wpPage(databaseId: { eq: 3851 }) {
        services {
          products {
            copy {
              heading
              content
              capabilities
            }
            caseStudies {
              link {
                title
                url
              }
              image {
                ...FluidHalfWidthImage
              }
              projectType
            }
          }
        }
      }
    }
  `)
  const { copy, caseStudies } = wpPage.services.products

  return (
    <TwoColumns
      id="products"
      reverse
      className="mt-0 text-center sm:mt-0 lg:mt-48 md:text-left"
      image={<TwoColumnGallery id="products" items={caseStudies} />}
      text={
        <div className="mt-5 md:mt-0 tc__text">
          <FadeIn delay="0">
            <h2 className="mb-8 font-serif text-38px">{copy.heading}</h2>
          </FadeIn>
          <FadeIn delay="100">
            <div
              className="style-links"
              dangerouslySetInnerHTML={{ __html: copy.content }}
            ></div>
          </FadeIn>
          {copy.capabilities && (
            <FadeIn delay="200">
              <h3 className="mt-16 mb-2 font-semibold uppercase text-squirrel text-iron">
                In-House Capabilities
              </h3>
              <div className="font-serif tracking-tight text-17px">
                {copy.capabilities}
              </div>
            </FadeIn>
          )}
        </div>
      }
    />
  )
}

export default Products
