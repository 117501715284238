import React, { useEffect, useState, useRef } from "react"

export const useAutoProgressTimeout = (started, frameLength, repeats) => {
	const [active, _set_active] = useState(-1)
	let timeout = useRef(null)

	const loop = () => {
		timeout.current = window.setTimeout(loop, frameLength * 1000)
		_set_active(_active => {
			return _active == repeats ? 0 : _active + 1
		})
	}

	const set_active = value => {
		_set_active(value)
		if (timeout.current) window.clearTimeout(timeout.current)
		timeout.current = window.setTimeout(loop, frameLength * 1000)
	}

	useEffect(() => {
		if (!started || typeof window == undefined) return
		loop()
		return () => {
			if (timeout.current) window.clearTimeout(timeout.current)
		}
	}, [started])

	return [active, set_active]
}
